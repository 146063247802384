* {
    user-select: none;
}

body > nav {
    color: var(--md-sys-color-on-secondary-container);
}

body > nav > .nav-wrapper {
    display: flex;
}

body > nav > .nav-wrapper > ul {
    padding: 0;
}

body > nav > .nav-wrapper > ul > li > a {
    color: var(--md-sys-color-on-secondary-container);
}

body > nav > .nav-wrapper > div {
    height: calc(100% - 4px);
    line-height: calc(var(--navbar-height) - 4px);
    padding: 2px 10px;
}

body > nav > .nav-wrapper > .input-field {
    flex-grow: 1;
}

body > nav > .nav-wrapper > .input-field > input {
    background-color: var(--md-sys-color-secondary-container);
}

body > nav > .nav-wrapper > .editor-resource-stats {
    line-height: calc(var(--navbar-height) / 2 - 2px);
    width: 200px;
}

body > #editor-container {
    height: calc(100vh - var(--navbar-height));
    overflow: hidden;
    position: relative;
}

body > #editor-container > .editor-grid-minor {
    height: calc(100% + 50px);
    position: absolute;
    width: calc(100% + 50px);
}

body > #editor-container > .editor-grid-minor:before {
    background-image:
        linear-gradient(to right, var(--md-sys-color-on-background) 1px, transparent 1px),
        linear-gradient(to bottom, var(--md-sys-color-on-background) 1px, transparent 1px);
    background-size: 10px 10px;
    content: " ";
    height: 100%;
    opacity: 0.03;
    position: absolute;
    width: 100%;
}

body > #editor-container > .editor-grid-minor > .editor-grid-major {
    height: 100%;
    width: 100%;
}

body > #editor-container > .editor-grid-minor > .editor-grid-major:before {
    background-image:
        linear-gradient(to right, var(--md-sys-color-on-background) 1px, transparent 1px),
        linear-gradient(to bottom, var(--md-sys-color-on-background) 1px, transparent 1px);
    background-size: 50px 50px;
    content: " ";
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
}

body > #editor-container > .editor-grid-minor > .editor-grid-major > #editor-offset {
    padding-left: calc(round(50vw, 50px) + 0.5px);
    padding-top: calc(round(50vh, 50px) + 0.5px);
    position: relative;
}

#editor-content {
    position: relative;
}

#editor-content > * {
    display: none;
    position: absolute;
}

#editor-content > div {
    background-color: var(--md-sys-color-tertiary-container);
    color: var(--md-sys-color-on-tertiary-container);
    margin-top: -10px;
    z-index: 1;
}

#editor-content > div.selected {
    box-shadow: var(--md-sys-color-primary) 0 0 5px;
}

#editor-content > svg {
    z-index: 0;
}

#editor-content > svg > path {
    fill: none;
    stroke: var(--md-sys-color-primary-container);
    stroke-linecap: round;
    stroke-width: 10px;
}

#editor-content > svg.invalid > path {
    stroke: var(--md-sys-color-error);
}

#editor-content > svg.selected > path {
    filter: drop-shadow(var(--md-sys-color-primary) 0 0 5px);
}

#editor-content > * > .block-title {
    background-color: var(--md-sys-color-tertiary);
    color: var(--md-sys-color-on-tertiary);
    line-height: 20px;
    height: 20px;
    text-align: center;
    width: 100%;
}

#editor-content > * > .block-form {
    display: flex;
    flex-direction: column;
    height: calc(100% - 20px);
    padding: 5px;
}

#editor-content > * > .block-form::before {
    content: " ";
    flex-grow: 1;
}

#editor-content > * > .block-form::after {
    content: " ";
    flex-grow: 1;
}

#editor-content > * > .block-form > * {
    margin: 4px 0;
}

#editor-content > * > .block-connector {
    background-color: var(--md-sys-color-primary-container);
    height: 20px;
    position: absolute;
    width: 10px;
}

#editor-content > * > .block-connector.block-connector-input {
    left: -10px;
}

#editor-content > * > .block-connector.block-connector-output {
    right: -10px;
}

#editor-content .input-field input {
    background-color: var(--md-sys-color-tertiary-container);
    color: var(--md-sys-color-on-tertiary-container);
    height: 36px;
    text-align: center;
}

#editor-content .input-field input + label {
    background-color: var(--md-sys-color-tertiary-container);
    border-radius: 4px;
    color: var(--md-sys-color-on-tertiary-container);
}

#editor-content .switch {
    --icon-size: 12px;
    --size-off: 8px;
    --size-on: 12px;
    --track-height: 16px;
    --track-width: 32px;
}

#editor-content .switch > label {
    display: flex;
}

#editor-content .switch > label > .lever {
    margin: 0 4px;
}

#editor-content .switch > label > .switch-label {
    flex-basis: 0;
    flex-grow: 1;
}

body > #editor-add-menu {
    width: 500px;
}

body > .fixed-action-btn > .btn-floating > i {
    color: var(--md-sys-color-on-primary-container);
}

body > ul.sidenav > li > .collapsible-header {
    border-bottom: 1px solid var(--md-sys-color-outline-variant);
    font-weight: bold;
}

body > ul.sidenav > li > .collapsible-body > .collapsible-item {
    border-bottom: 1px solid var(--md-sys-color-outline-variant);
    line-height: 1.5;
    padding: 1rem 2rem;
}

body > #editor-color-modal {
    width: calc(300px + 2 * var(--modal-padding));
}
